.test-items-wrap {
  .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    padding: 0;
    background-color: transparent;
    .mat-mdc-form-field-infix {
      padding: 0;
      border: 0;
      width: 100%;
      min-height: auto;
    }
  }
  .mat-mdc-text-field-wrapper {
    padding: 0;
    background-color: transparent;

    .mat-mdc-form-field-focus-overlay {
      bottom: 0;
      opacity: 0;
    }
  }

  .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper.mdc-text-field {
    height: auto;
  }

  .focus-items-quantity {
    display: flex;
    align-items: center;
    .mat-mdc-form-field-error-wrapper {
      padding-right: 0;
      padding-left: 0;
      padding-top: 4px;
    }
    .mat-mdc-text-field-wrapper {
      width: 3rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      height: 2rem;
    }
  }
}
