.branch-select {
  &.mat-form-field-appearance-fill {
    .mat-mdc-text-field-wrapper {
      background-color: transparent;
      height: 42px;
      &.mdc-text-field {
        padding: 0;
        will-change: unset;
      }
      .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
      }
    }
  }
}

.select-branch-wrapper .select-branch-form {
  .mat-mdc-text-field-wrapper {
    height: 51px;
    box-shadow: 3px 3px 6px 2px rgba(0, 67, 135, 0.8);
    line-height: normal;
    white-space: nowrap;
    .mat-mdc-form-field-flex {
      box-shadow: 3px 3px 6px 2px rgba(0, 67, 135, 0.8);
      padding: 0 0.75em 0 0.75em;
      height: 51px;
    }
  }
  .mat-mdc-floating-label {
    font-size: 14px;
  }
  .mat-mdc-form-field-error {
    font-size: 10.5px;
  }
  .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    background-color: rgba(0, 0, 0, 0.04);
    padding-right: 2px;
  }
}

.cdk-overlay-container {
  .mat-mdc-option .mdc-list-item__primary-text {
    letter-spacing: normal;
    font-size: 1.6rem;
  }
  .select-branch-form .mdc-menu-surface.mat-mdc-autocomplete-panel {
    top: 0;
  }
  .select-branch-autocomplete {
    color: var(--color-secondary-light-gray);
    top: 0.2rem;

    &.mdc-menu-surface.mat-mdc-autocomplete-panel {
      position: relative;
      padding-top: 0;
    }

    .mat-mdc-option.mdc-list-item--selected {
      background: var(--color-neutral-white);
    }

    .mat-mdc-option:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    .mat-mdc-option.mdc-list-item--selected .mdc-list-item__primary-text {
      color: rgba(0, 0, 0, 0.87);
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    mat-pseudo-checkbox {
      display: none;
    }
  }
}

.select-branch-wrapper {
  .label {
    font-weight: bold;
    font-size: 1.8rem;
  }
  .branch-select {
    width: 44.3rem;
    height: 8.8rem;
    display: inline-block;
    position: relative;
  }

  .header-branch-select {
    .branch-select {
      width: 30rem;
      height: 4rem;
      border-right: 0.1rem solid var(--color-primary-blue);
      border-left: 0.1rem solid var(--color-primary-blue);
      display: inline-block;
      position: relative;
      background-color: transparent;
      padding-bottom: 1.5rem;
      font-weight: bold;

      &.mat-form-field-appearance-fill {
        .mat-mdc-text-field-wrapper {
          background-color: transparent;
          height: 42px;
        }
        .mat-mdc-form-field-infix {
          border: none;
          padding-top: 8px;
          font-size: 1.6rem;
          font-weight: bold;
        }
        .mat-mdc-form-field-flex {
          background-color: none;
          padding: 0;
        }
      }
    }
    .header-select-branch-form {
      display: flex;
    }
    .selected-branch-input {
      width: 30rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0.6rem 0 0.6rem;
      white-space: nowrap;
      font-weight: bold;
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: var(--color-primary-blue);
    }
  }
}
