@use "variables";
@use "layout";
@use "base";
@use "custom-material-ui";
@use "header";
@use "select-branch";
@use "user-and-language";
@use "create-test-inspection-modal";
@use "inspection-group";
@use "inspection-result";
@use "fonts";
@use "form-item-dialog";

@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/flex-utils.css";

@import "@uag/fonts";
@import "material-symbols/outlined.css";
@import "@doka/common/styles/global.scss";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
