.create-test-inspection {
  .mat-mdc-text-field-wrapper {
    label {
      font-size: 1.4rem;
      will-change: unset;
    }
    .mat-mdc-select-value-text {
      font-size: 1.4rem;
    }
  }
  mat-error {
    font-size: 1.05rem;
  }
}
