.language-button {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
}

.user-and-language {
  .user-name {
    .mdc-button__label {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      line-height: normal;
      white-space: nowrap;
    }
  }
}

.language-option {
  .mat-pseudo-checkbox {
    display: none;
  }
  &.mat-mdc-option {
    .mdc-list-item__primary-text {
      margin-right: 0;
    }
  }
}
