// Flex Utils
.flex {
  display: flex;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-right {
  justify-content: right;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.none-text-right {
  display: none;
}
.text-center {
  text-align: center;
}
.display-none {
  display: none;
}

.width-100 {
  width: 100%;

  &.doka-button {
    width: 100%;
    font-size: 1.4rem;
    cursor: pointer;
  }
}

.page-container {
  overflow: auto;
  height: calc(100vh - 7rem);
}

.text-sm {
  font-size: 1.2rem;
}

.required:after {
  content: " *";
  color: var(--color-error);
}

.page-title {
  font-size: 3.6rem;
  font-weight: bold;
  position: sticky;
  background-color: var(--color-neutral-white);
  width: 100%;
  top: 0;
  padding: 3.2rem 6.25rem;
  z-index: 2;
  .page-subtitle {
    font-size: 1.6rem;
  }
}

.color-primary-blue {
  color: var(--color-primary-blue);
}

a {
  cursor: pointer;
  color: var(--color-primary-blue);
}

.main-button {
  &.doka-button {
    font-size: 1.4rem;
    cursor: pointer;
    height: 36px;

    .doka-icon {
      font-size: 2.4rem;
    }

    &.doka-button-danger {
      font-size: 1.4rem;
    }
  }
}

.button-icon {
  &.doka-button {
    &.icon-only {
      height: 0;
    }

    .doka-icon {
      font-size: 2rem;
    }
  }
}

// Space Utils
@for $i from 1 to 10 {
  .my-#{$i} {
    margin-top: $i * 0.4rem;
    margin-bottom: $i * 0.4rem;
  }
  .mx-#{$i} {
    margin-left: $i * 0.4rem;
    margin-right: $i * 0.4rem;
  }
  .mb-#{$i} {
    margin-bottom: $i * 0.4rem;
  }
  .mt-#{$i} {
    margin-top: $i * 0.4rem;
  }
  .ml-#{$i} {
    margin-left: $i * 0.4rem;
  }
  .mr-#{$i} {
    margin-right: $i * 0.4rem;
  }

  .p-#{$i} {
    padding: $i * 0.4rem;
  }
  .py-#{$i} {
    padding-top: $i * 0.4rem;
    padding-bottom: $i * 0.4rem;
  }
  .px-#{$i} {
    padding-left: $i * 0.4rem;
    padding-right: $i * 0.4rem;
  }
  .pb-#{$i} {
    padding-bottom: $i * 0.4rem;
  }
  .pt-#{$i} {
    padding-top: $i * 0.4rem;
  }
  .pl-#{$i} {
    padding-left: $i * 0.4rem;
  }
  .pr-#{$i} {
    padding-right: $i * 0.4rem;
  }

  .space-x-#{$i} {
    *:not(:last-child) {
      margin-right: $i * 0.4rem;
    }
  }
}
