.mat-mdc-tab-labels {
  border-bottom: 1px solid var(--color-secondary-dove);
}

.filters-container {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mdc-text-field--filled {
    background-color: var(--color-secondary-gray);
    border-bottom: 1px solid var(--color-secondary-dark-gray-30);
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .filter-selector {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: var(--color-secondary-gray);
      border-bottom: 1px solid var(--color-secondary-dark-gray-30);
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }
}

.failures-list {
  h2,
  h3 {
    font-weight: bold;
    color: var(--color-primary-blue);
  }

  h2 {
    font-size: 2.8rem;
  }
  
  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--color-secondary-dark-gray-50);
  }
}

.pdf-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}

.gallery-overview {
  .image-slider {
    display: grid;
    margin-left: 2rem;
    width: 28rem;
  }
}

.test-details-pdf-preview-modal {
  .test-details-tab {
    width: 135rem;
  }
}

.failures-list-container {
  img {
    cursor: pointer;
    margin-block: 1rem;
  }
  .failure-list {
    margin-top: 0;
  }
}

.list-item-line {
  padding-left: 2.5rem;
}

.failure-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.gallery-overview-tab-wrapper {
  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}
