:root {
  --color-neutral-white: #fff;
  --color-primary-yellow: #ffdd00;
  --color-primary-blue: #004588;
  --color-secondary-blue: #ebf2f9;
  --color-secondary-dark-gray-100: #22272c;
  --color-secondary-dark-gray-90: #383c40;
  --color-secondary-dark-gray-50: #707070;
  --color-secondary-dark-gray-30: #b5b7b8;
  --color-secondary-dark-gray-10: #f7f8f9;
  --color-secondary-gray: #dedfe0;
  --color-secondary-light-gray: #eff0f0;
  --color-secondary-light-yellow: #fceda5;
  --color-secondary-light-orange: #fbcd9a;
  --color-secondary-light-mauve: #bda0cd;
  --color-secondary-light-violet: #8e9dc8;
  --color-secondary-sky-blue: #7ab9d4;
  --color-secondary-emerald: #9acca5;
  --color-secondary-yellow-green: #d4e2a7;
  --color-secondary-dove: #c5c5c7;
  --color-error: #f44336;
}
