.select-branch-section {
  .doka-button {
    cursor: pointer;
  }
}

.dashboard-button {
  .content-wrapper {
    img {
      max-width: max-content;
    }
  }
}