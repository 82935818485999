*,
*::after,
*::before {
  box-sizing: border-box;
  letter-spacing: normal;
}

html {
  font-size: 10px;

  body {
    height: 100%;
    font-size: 16px;
  }
}

body {
  color: var(--color-secondary-dark-gray-90);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}